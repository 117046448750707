<template>
  <Loading :isLoading="isLoading"/>
    <div v-if="rulesLoaded">
        <div class="w-full">
            <h2 class="text-center font-bold text-3xl" style="color:#810042">Nuevo Esquema de Comisiones</h2>
        </div>

        <div class="sm:w-1/2  lg:w-3/5 2xl:w-2/5 m-auto mt-10">
            <p for="input_schema_name_commission_scheme">Nombre del Esquema:</p>
            <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText" name="input_schema_name_commission_scheme" id="input_schema_name_commission_scheme"
            :maxlength="51"
            v-model="v$.name_commission_scheme.$model" @blur="v$.name_commission_scheme.$validate"
            :class="{'invalid-form':v$.name_commission_scheme.$error, 'valid-form':!v$.name_commission_scheme.$invalid}">

            <div v-for="(error,index) of v$.name_commission_scheme.$errors" :key="index">
              <div class="errors" :id="'error_name_commission_scheme_'+index">{{ error.$message }}</div>
            </div>

        </div>

        <div class="sm:w-1/2 lg:w-3/5 2xl:w-2/5  m-auto mt-10">
            <ChooseButton
                ref="chooseButtonComponent"
                :buttons="choosethepaymentmethod"
                v-on:choose-button="selectChoosePaymentmethod($event)"
                label="Elige como se va a pagar:"/>
            
            <div v-for="(error,index) of v$.commission_scheme.$errors" :key="index">
              <div class="errors" :id="'error_amount_commission_scheme_'+index" >{{ error.$message }}</div>
            </div>

        </div>

         <div class="sm:w-1/2 lg:w-3/5  2xl:w-2/5  m-auto mt-10">
            <div class="sm:relative grid grid-cols-1 sm:grid-cols-2 sm:gap-5 mt-10">
                <div>
                    <p>Producto:</p>
                    <select class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText" id="select_product_commission_scheme" name="select_product_commission_scheme"
                    v-model="v$.product.$model" :class="{'invalid-form':v$.product.$error, 'valid-form':!v$.product.$invalid}"> 
                        <option value="" selected disabled>Seleccione una de las opciones</option>
                        <option value="Ahorro">Ahorro</option>
                        <option value="Inscripcion asistida">Inscripcion asistida</option>
                    </select>
                    
                    <div v-for="(error,index) of v$.product.$errors" :key="index">
                        <div class="errors" :id="'error_product_commission_scheme_'+index">{{ error.$message }}</div>
                    </div>
                </div>

                <div v-if="v$.commission_scheme.$model=='fijo'">
                    <p>Ingresar Importe:</p>
                    <div class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText flex" :class="{'invalid-form':v$.importOrPercentage.$error && _errorsImport, 'valid-form':!v$.importOrPercentage.$invalid && _errorsImport}" >
                        <span style="color: #CABBBB;">$</span>
                        <input type="text" style="outline:0;" class="mx-2 w-full" @blur="_errorsImport=true" v-money="money" id="input_fixed_amount_commission_scheme" name="input_fixed_amount_commission_scheme"
                        v-model="v$.importOrPercentage.$model">
                    </div>
                    
                    <div v-if="_errorsImport">
                        <div  v-for="(error,index) of v$.importOrPercentage.$errors" :key="index" >
                            <div class="errors" :id="'error_ixed_amount_commission_scheme_'+index">{{ error.$message }}</div>
                        </div>
                    </div>
                    
                </div>

                <div v-if="v$.commission_scheme.$model=='porcentaje'">
                    <p>Ingresar Porcentaje:</p>
                    <div class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText flex" :class="{'invalid-form':v$.importOrPercentage.$error && _errorsImport, 'valid-form':!v$.importOrPercentage.$invalid && !_errorsImport}" >
                        <span style="color: #CABBBB;">%</span>
                        <input type="text" style="outline:0;" class="mx-2 w-full" id="input_percentage_commission_scheme_" name="input_percentage_commission_scheme" @blur="_errorsImport=true"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode==46)"
                        v-model="v$.importOrPercentage.$model" :maxlength="6"
                        >
                    </div>

                    <div v-if="_errorsImport">
                        <div v-for="(error,index) of v$.importOrPercentage.$errors" :key="index">
                            <div class="errors" :id="'input_percentage_commission_scheme_'+index">{{ error.$message }}</div>
                        </div>
                    </div>
                </div>

                <div class="sm:absolute -right-20 top-12 h-20 w-20" v-if="v$.commission_scheme.$model!=''">
                    <button @click="saveNewCommissionScheme()" id="icon_add_new_product_commission_scheme"><img src="@/assets/more.svg"  width="50" height="50"></button>
                </div>               
            </div>
           
        </div>

        <div class="sm:w-1/2  lg:w-3/5 2xl:w-2/5 m-auto mt-10">
            <p class="font-medium">Productos agregados al Esquema</p>
            <table class="table-elements ">
                <thead>
                    <tr>
                        <th>Productos</th>
                        <th>Tipo de pago</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(commision,index)  of  commissionSchemeList" :key="index"  
                    :style="[(index%2==0)? {'background': '#FFF'}:{'background': '#efefef' }]"
                    :id="'label_amount_added_commission_scheme_'+index"    
                    >
                        <th>{{commision.product_name}}</th>
                        <th>
                            <label v-if="commision.comission_type=='fijo'"> $ {{commision.value}}</label>
                            <label v-if="commision.comission_type=='porcentaje'">{{commision.value}} %</label>    
                        </th>
                        <th><button style="outline:0" @click="removeScheme(index)" :id="'icon_delete_product_commission_scheme_'+index" ><img height="25" width="25" src="@/assets/eliminar.svg"></button></th>
                    </tr>
                </tbody>
            </table> 
        </div>

          <div class="sm:w-1/2  lg:w-3/5 xl:w-2/5  m-auto mt-20 ">
             <button class="shadow-md rounded-lg w-full mx-auto px-5 py-5 mt-2  btn-save" id="button_save_bonus_scheme" @click="saveCommisionScheme()" >Guardar</button>
          </div>

    </div>
</template>

<script>
import('@/assets/css/stylesheet.css');
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import Loading from '@/components/Loading/VueLoading.vue'
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf
} from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import { helpers } from '@vuelidate/validators';
import { VMoney } from "v-money";
import { onBeforeMount, reactive, ref } from 'vue-demi';
import validations from '@/helpers/validations';
import CommissionScheme from "@/classes/CommissionScheme.js"
import {getTokenDecoden} from "@/helpers/tokenauth";
export default {
    name:"NewCommissionScheme",
    components:{
        ChooseButton,
        Loading
    },
    directives: { money: VMoney },
    setup() {
        let isLoading= ref(false);
        let rulesLoaded = ref(false);
        let commissionSchemeList=ref([]);
        let _errorsImport=ref(false);
        const chooseButtonComponent=ref(null);
        const  choosethepaymentmethod=[
        { id: "button_fixed_amount_commission_scheme", value: "fijo", text: "Importe Fijo"},
        { id: "button_percentage_commission_scheme", value: "porcentaje", text: "Porcentaje" }];
        let money=ref( {
            decimal: ".",
            thousands: ",",
            prefix: "",
            suffix: "",
            precision: 2,
            masked: false /* doesn't work with directive */,
        });
        let rulesValidations = ref({}); //Reglas de validaciones.
        let rulevalidations=reactive({
            name_commission_scheme:{
                required:helpers.withMessage("El campo Nombre del Esquema es requerido.",requiredIf(function(){return rulesValidations.value.name_commission_scheme.required})),
                minlength:helpers.withMessage(()=>{return "El campo Nombre del Esquema debe tener mínimo  "+ rulesValidations.value.name_commission_scheme.minLength+" caracteres."},(value)=>{return rulesValidations.value.name_commission_scheme.minLength<=value.length}),
                maxlenghvalue:helpers.withMessage(()=>{return "El campo Nombre del Esquema debe tener máximo "+ rulesValidations.value.name_commission_scheme.maxLength +" caracteres."},(value)=>{return rulesValidations.value.name_commission_scheme.maxLength>=value.length}),
                isAlphaNumeric:helpers.withMessage("El campo Nombre del Esquema no admite caracteres especiales.",(value)=>value != ""? new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/g).test(value): true)
            },
            commission_scheme:{
                required:helpers.withMessage("Elige como se va a pagar.",requiredIf(function(){return rulesValidations.value.commission_scheme.required}))
            },
            product:{
                 required:helpers.withMessage("Seleccione una de las opciones.",requiredIf(function(){return rulesValidations.value.product.required}))
            },
            importOrPercentage:{
                require:helpers.withMessage(()=>{
                    if(commissionScheme.value.commission_scheme=="porcentaje"){
                        return "El campo porcentaje es requerido.";
                    }else{
                        return "El campo Importe es requerido.";
                    }
                },function(value){
                    if(rulesValidations.value.importOrPercentage.required==true){
                        if(commissionScheme.value.commission_scheme=="fijo" && value!=0){return true}
                        if(commissionScheme.value.commission_scheme=="porcentaje" && value!=0 && value!= null){return true}
                    }
                    if(rulesValidations.value.importOrPercentage.required==false){return false;}
                }),
                greaterThanZero:helpers.withMessage(()=>{
                    if(commissionScheme.value.importOrPercentage==0 && commissionScheme.value.commission_scheme=="porcentaje"){
                        return "El campo porcentaje debe ser mayor a 0."
                    }
                    else{
                        return "El campo Importe debe ser mayor a 0."
                    }
                },function(value){return value==0?false:true}),
                
                greaterThanHundred:helpers.withMessage(()=>{
                    if(commissionScheme.value.importOrPercentage>100 && commissionScheme.value.commission_scheme=="porcentaje"){
                        return "El campo porcentaje debe ser menor o igual a 100.";
                    }else{
                        return null
                    }
                },function(value){ 
                    if(value<=100 && commissionScheme.value.commission_scheme=="porcentaje"){return value<=100 && commissionScheme.value.commission_scheme=="porcentaje"}
                    if(parseFloat(fomartNumber(commissionScheme.value.importOrPercentage))>0 && commissionScheme.value.commission_scheme=="fijo"){return true}
                }),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",(value)=> {
                    if(commissionScheme.value.commission_scheme=="porcentaje" && value!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(value)
                    }
                    else return true;
                })
            },
            
        });
        let commissionScheme=ref({
            name_commission_scheme:"",
            commission_scheme:"",
            // product_commission_scheme:"",
            product:"",
            importOrPercentage:null
        });
        let v$=useVuelidate(rulevalidations,commissionScheme);
        onBeforeMount(()=>{
            // sessionStorage.setItem("login",'{"processed":true,"message":"Se encontro correctamente","token":"eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MTg2LCJidXNpbmVzcyI6ImZvbnNudGUifQ.Ko-uKip_ONDIIxO7qcq_bYRYVQHFSFY20HbrxafM2C0","id":186,"email":"fredy.gutierrez@gcabsa.com.mx","created_at":"2022-03-07T18:25:29.901Z","updated_at":"2022-03-07T18:25:55.476Z","rfc":"GUVF951024PJ1","paternal_name":"Gutiérrez","maternal_name":"Vázquez","middle_name":"","name":"Fredy","associate_id":"","name_associate":"","tipo_cuenta":"administrator","person_id":333,"referal_code":"mem055"}')
            getRulesValidations(); //consulta de las reglas de validaciones.
        });
        async function getRulesValidations(){
            const response=await validations.getStructure();
            rulesValidations.value = response.data.validations.commission_scheme;
            rulesLoaded.value=true;
        }
        function selectChoosePaymentmethod(value){
            commissionScheme.value.commission_scheme=value;
            commissionScheme.value.importOrPercentage="";
            _errorsImport.value=false;
        }
        function validation(){
            this.v$.$validate();
            return this.v$.$error;
        }
        async function saveNewCommissionScheme(){
            _errorsImport.value=true;
            this.v$.$validate();
            if(!this.v$.$error){
                const newModelOfScheme=new Object({
                    product_name: commissionScheme.value.product,
                    comission_type: commissionScheme.value.commission_scheme,
                    value: commissionScheme.value.importOrPercentage,
                    user_id: (getTokenDecoden()).id
                });
                if((commissionSchemeList.value.find(element=>element.product_name==newModelOfScheme.product_name && element.comission_type==newModelOfScheme.comission_type ?true:false))){
                    Swal.fire({
                        title: "Aviso",
                        html: 'Ya se tiene el producto <strong>"'+newModelOfScheme.product_name+'"</strong> registrado con el mismo metodo de pago.',
                        icon: "warning",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    
                    return 
                }
                _errorsImport.value=false;
                commissionSchemeList.value.push(newModelOfScheme);
                this.v$.$reset();
                resetForm();// resetear formulario
            }
        }
        function resetForm(){
            commissionScheme.value.product=null;
            commissionScheme.value.importOrPercentage=null;
        }
        function removeScheme(index){
        Swal.fire({
            title: "Aviso",
            text: "¿Desea eliminar el producto?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FFFFFF',
            }).then((result) => {
            if (result.value) {
                    commissionSchemeList.value.splice(index,1);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Producto eliminado.'
                    })             
                }
            });
            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete') 
        }
        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }
        function fomartNumber(value){
            if (new RegExp(/[\.,]/g).test(value) == true) {
                value = value.replace(
                    /[\.,]/g,
                    ""
                );
            }
            return value;
        }
        async function saveCommisionScheme(){
            _errorsImport.value=true;
            isLoading.value = true;
            rulesLoaded.value=false;
            this.v$.$validate();
            let newModelCommisionScheme=new CommissionScheme();
            if(commissionSchemeList.value.length>0){
                if(commissionScheme.value.name_commission_scheme=="" && commissionScheme.value.name_commission_scheme.length>=2){
                    Swal.fire({
                        title: "Aviso",
                        text: "Favor de capturar el nombre del esquema.",
                        icon: "warning",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                     isLoading.value = false;
                     rulesLoaded.value=true;
                    _errorsImport.value=false;
                    return;
                }
                try {
                    commissionSchemeList.value.forEach(element => {
                        if(element.comission_type!="porcentaje"){
                            element.value=parseInt(((fomartNumber(element.value))).toString());
                        }
                    });
                    newModelCommisionScheme.dataCommissionSchem.users_id=(getTokenDecoden()).id;
                    newModelCommisionScheme.dataCommissionSchem.name=commissionScheme.value.name_commission_scheme;
                    newModelCommisionScheme.dataCommissionSchem.token_auth=sessionStorage.getItem("login");
                    newModelCommisionScheme.dataCommissionSchem.sale_commission_schema_detail_attributes=Object.assign({}, commissionSchemeList.value)
                    const response=await newModelCommisionScheme.save(newModelCommisionScheme.dataCommissionSchem,`/comission_schema/register`);
                    if(response.data.response.has_errors){      
                        commissionSchemeList.value.forEach(element => {
                            if(element.comission_type!="porcentaje"){
                                element.value=((parseInt(element.value))/100);
                            }
                        });
                       
                         let messages="";
                            response.data.errors.forEach(element => {
                                 if(element=="Nombre del esquema ya existe"){
                                     messages+="<strog> El nombre del esquema ya existe, ingresé otro nombre de esquema para continuar. </strong><br>"
                                 }
                                 else{
                                    messages+="<strong>■ "+element+".</strong><br>"
                                 }
                        });
                        Swal.fire({
                            title: "Aviso",
                            // html:response.data.response.message+"<br>"+messages,
                            html:messages,
                            icon: "info",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        }); 
                        isLoading.value = false;
                        rulesLoaded.value=true;
                        _errorsImport.value=false;
                        return;
                    }else{
                       Swal.fire({
                            title: "El Nuevo Esquema de Comisiones ha sido registrado correctamente.",
                            // text: response.data.response.message,
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        }); 
                    }
                commissionSchemeList.value=[];
                resetForm();
                commissionScheme.value.name_commission_scheme=null;
                this.v$.$reset();
                isLoading.value = false;
                rulesLoaded.value=true;
                _errorsImport.value=false;
                
                } catch (err) {
                    Swal.fire({
                        title: "Aviso",
                        text: "Ocurrio un error al registrar los datos.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    isLoading.value = false;
                    rulesLoaded.value=true;                 
                }
            }
            else{
                Swal.fire({
                    title: "Aviso",
                    text: "No se puede concretar esta acción debido a que no hay ningún elemento agregado ",
                    icon: "warning",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
                isLoading.value = false;
                rulesLoaded.value=true;
            }
        }
      return{
        v$,
        isLoading,
        choosethepaymentmethod,
        money, //tipo de dato de moneda
        rulesLoaded,
        validation,
        selectChoosePaymentmethod,
        saveNewCommissionScheme,
        commissionSchemeList,
        removeScheme,
        chooseButtonComponent, //referencia del componente de los botones
        saveCommisionScheme,
        commissionScheme,
        _errorsImport
      }
    },
   watch:{
        'v$.importOrPercentage.$model':function () {
            if(this.v$.commission_scheme.$model=="porcentaje" && this.commissionScheme.importOrPercentage!=null){
                if (new RegExp(/^[a-zA-Z]+$/g).test(this.v$.importOrPercentage.$model) == true) {
                    this.commissionScheme.importOrPercentage= this.commissionScheme.importOrPercentage.replace(
                    /[a-zA-Z]+/,"");
                }
            }
      },
   }
}
</script>

<style scoped>
.btn-save{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.swal2-actions{
    direction: rtl;
}

#button_cancel_delete{
    color: #810042;
    border: 1px solid #810042;
}

#input_schema_name_commission_scheme:focus-visible,
#input_schema_name_commission_scheme:focus-visible{
  border: 2px solid #810042 ;
  outline: none;
}

.table-elements{
    text-align: left;
    width: 100%;    
}
.table-elements thead{
    border-bottom:3px solid #ddd;
}
.table-elements thead th,
.table-elements tbody th{
    padding:10px ;
}

.table-elements tbody tr:nth-child(2n) {
  background: #FFC001;
}
</style>